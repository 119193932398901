import { eachDayOfInterval, parseISO } from 'date-fns';
import { mapValues } from 'lodash';

import { toDateString } from '../../common/dates';
import { toMeasurementValue } from '../../common/measurements';
import { isMetricKey, MetricKey } from '../../common/metrics';
import {
  BlackoutPeriod,
  Coach,
  DataExportItem,
  Group,
  GroupAggregates,
  GroupConfig,
  GroupDataAccessLevel,
  GroupLabel,
  GroupMembersDailyMetrics,
  GroupMembership,
  GroupNotes,
  GroupRole,
  GroupStats,
  MarkerType,
  MetricAggregates,
  MetricSet,
  Organization,
  OrganizationDataExportSettings,
  OrganizationSettings,
  OrganizationSubscription,
  OrganizationSubscriptionPlan,
  OrganizationSubsriptionOption,
  OrganizationSummary,
  OrganizationType,
  OrganizationUsedSeatCounts,
  OrgMemberSearchResult,
  TeamsInvitation,
  TeamsInvitationGroup,
  TermsStatus,
  ViewSettings,
} from '../../common/researchTypes';
import { logException } from '../../common/sentry';
import { GroupMemberNotes, LocaleData } from '../../common/types';
import { assertNever, booleanObjectToList } from '../../common/utils';
import {
  ApiBlackoutPeriod,
  ApiGetDataExportsResponse,
  ApiGetGroupMemberNotesResponse,
  ApiGetGroupNotesResponse,
  ApiGetOrganizationDataExportSettingsResponse,
  ApiGroup,
  ApiGroupAggregates,
  ApiGroupConfig,
  ApiGroupDataAccess,
  ApiGroupDataAccessLevel,
  ApiGroupLabel,
  ApiGroupMembership,
  ApiGroupRole,
  ApiGroupStats,
  ApiMetricAggregates,
  ApiMetricSetResponse,
  ApiOrganization,
  ApiOrganizationMembership,
  ApiOrganizationSettings,
  ApiOrganizationSubscription,
  ApiOrganizationSubscriptionOption,
  ApiOrganizationSubscriptionPlan,
  ApiOrganizationSummary,
  ApiOrganizationType,
  ApiOrganizationUsedSeatCounts,
  ApiOrganizationViewSettingsJSON,
  ApiOrganizationViewSettingsResponse,
  ApiOrgMemberSearchResult,
  ApiTeamsInvitation,
  ApiTermsStatus,
  GetGroupMembersDailyMetricsResponse,
  UserNoteStatus,
} from '../types';
import { toUserNotes } from './common';
import { parseTimestamp } from './time';

export function toApiViewSettingsJSON(
  view: ViewSettings,
): ApiOrganizationViewSettingsJSON {
  return {
    version: 1,
    view: {
      date_range_days: view.dateRangeDays,
      markers: booleanObjectToList(view.enabledMarkers) as MarkerType[],
      selected_metric_set_uid: view.selectedMetricSetID,
      metric_set_uids_ordered: view.metricSetUidsOrdered,
    },
  };
}

export function toViewSettings(
  response: ApiOrganizationViewSettingsResponse,
): ViewSettings {
  const { view } = response.json;

  return {
    dateRangeDays: view.date_range_days,
    enabledMarkers: {
      average: view.markers.includes('average'),
      movingAverage: view.markers.includes('movingAverage'),
      baseline: view.markers.includes('baseline'),
    },
    selectedMetricSetID: view.selected_metric_set_uid,
    metricSetUidsOrdered: view.metric_set_uids_ordered,
  };
}

export function toMetricSet(response: ApiMetricSetResponse): MetricSet {
  const { name, metrics } = response.json;
  return {
    name,
    id: response.uid,
    metrics: metrics.filter(isMetricKey),
  };
}

export function toGroupStats(data: ApiGroupStats): GroupStats {
  // The arrays are reversed so that they are in chronological order (oldest first)
  return {
    memberJoins: data.participants_join_timestamps
      .map((dateString) => parseTimestamp(dateString))
      .reverse(),
    memberLeaves: data.participants_leave_timestamps
      .map((dateString) => parseTimestamp(dateString))
      .reverse(),
  };
}

function toOrganizationType(orgType: ApiOrganizationType): OrganizationType {
  switch (orgType) {
    case 'coach':
    case 'research':
      return orgType;
    case 'health_private':
      return 'hrm_anonymous';
    case 'illness_detection':
      return 'hrm_non_anonymous';
    default:
      assertNever(orgType);
      return 'research';
  }
}

export function toOrganization(org: ApiOrganization): Organization {
  return {
    ...toOrganizationSummary(org),
    groups: org.groups.map(toGroup),
    invitations: org.invited_members.map(toTeamsInvitation),
    coaches: org.members.map(toCoach),
    terms: toTermsStatus(org.terms),
    usedSeatCounts: toOrganizationUsedSeatCounts(org.used_seat_counts),
  };
}

function toOrganizationUsedSeatCounts(
  counts: ApiOrganizationUsedSeatCounts,
): OrganizationUsedSeatCounts {
  return {
    member: counts.participant,
  };
}

export function toGroupRole({
  uid: id,
  membership_uid: coachID,
  role,
  created_at: createdAt,
}: ApiGroupRole): GroupRole {
  return {
    id,
    coachID,
    role,
    createdAt: parseTimestamp(createdAt),
  };
}

function toDataAccessLevel(data_access: ApiGroupDataAccess) {
  switch (data_access.level) {
    case ApiGroupDataAccessLevel.AllTime:
      return {
        level: GroupDataAccessLevel.AllTime,
      };
    case ApiGroupDataAccessLevel.ParticipationTime:
      return {
        level: GroupDataAccessLevel.ParticipationPeriod,
      };
    default:
      logException(
        new Error(`Unknown group data access level! ${data_access.level}`),
      );
      return assertNever(data_access.level);
  }
}
function toGroupConfig({
  data_access,
  redirects,
}: ApiGroupConfig): GroupConfig {
  return {
    dataAccess: toDataAccessLevel(data_access),
    redirects,
  };
}

export function toGroup({
  uid: id,
  member_count: memberCount,
  created_at,
  invite_link: inviteLink,
  is_anonymous: isAnonymous,
  auto_guidance_enabled: autoGuidanceEnabled,
  is_private: isPrivate,
  admin_alerts_enabled: adminAlertsEnabled,
  config,
  employee_wellness = false,
  ...rest
}: ApiGroup): Group {
  return {
    ...rest,
    createdAt: parseTimestamp(created_at),
    id,
    memberCount,
    inviteLink,
    isAnonymous,
    autoGuidanceEnabled,
    adminAlertsEnabled,
    isPrivate,
    config: toGroupConfig(config),
    roles: rest.roles.map(toGroupRole),
    employeeWellness: employee_wellness,
  };
}

export function toOrganizationSettings({
  send_new_org_member_email: newCoachNotifications,
  send_new_study_participant_email: newGroupMemberNotifications,
  send_revoked_study_participation_email: revokedGroupMembershipNotifications,
}: ApiOrganizationSettings): OrganizationSettings {
  return {
    newCoachNotifications,
    newGroupMemberNotifications,
    revokedGroupMembershipNotifications,
  };
}

function toOrganizationSubscriptionPlan(
  plan: ApiOrganizationSubscriptionPlan,
): OrganizationSubscriptionPlan {
  switch (plan.name) {
    case 'hrm':
      return OrganizationSubscriptionPlan.HRM;
    case 'teams':
      return OrganizationSubscriptionPlan.Teams;
    case 'legacy_teams':
      return OrganizationSubscriptionPlan.Legacy;
    default:
      logException(
        new Error(`Unknown subscription plan! ${JSON.stringify(plan)}`),
        plan,
      );
      return OrganizationSubscriptionPlan.Teams;
  }
}

function toOrganizationSubscriptionOption(
  option: ApiOrganizationSubscriptionOption,
): OrganizationSubsriptionOption {
  return {
    validFrom: parseISO(option.added_at),
    validTo: option.removed_at ? parseISO(option.removed_at) : undefined,
    memberSeatCount: option.data.participant_seat_count,
    blackoutMode: option.data.blackout_mode,
  };
}

export function toOrganizationSubscription(
  sub: ApiOrganizationSubscription,
): OrganizationSubscription {
  return {
    id: sub.subscription_uid,
    plan: toOrganizationSubscriptionPlan(sub.plan),
    validFrom: parseISO(sub.valid_from),
    validTo: sub.valid_to ? parseISO(sub.valid_to) : undefined,
    isTrial: sub.status === 'trial',
    options: sub.options.map(toOrganizationSubscriptionOption),
  };
}

function toCoach({
  membership_uid: id,
  email,
  role,
}: ApiOrganizationMembership): Coach {
  return {
    id,
    email,
    role,
  };
}

export function toOrganizationSummary(
  org: ApiOrganizationSummary,
): OrganizationSummary {
  return {
    name: org.name,
    id: org.organization_uid,
    ownCoachID: org.membership.membership_uid,
    organizationType: toOrganizationType(org.organization_type),
    createdAt: parseTimestamp(org.created_at),
  };
}

function toTermsStatus(terms: ApiTermsStatus): TermsStatus {
  return {
    latestVersion: terms.latest_version,
    acceptedVersion: terms.accepted_version,
  };
}

export function toTeamsInvitation({
  invitation_uid: id,
  recipient_name: recipientName,
  expires_at: expiresAt,
  is_expired: isExpired,
  sent_at: sentAt,
  email,
}: ApiTeamsInvitation): TeamsInvitation {
  return {
    id,
    email,
    recipientName,
    sentAt: parseTimestamp(sentAt),
    expiresAt: parseTimestamp(expiresAt),
    isExpired,
  };
}

export function toTeamsInvitationsGrouped(
  data: TeamsInvitation[],
): TeamsInvitationGroup {
  return {
    active: data.filter((invitation) => !invitation.isExpired),
    expired: data.filter((invitation) => invitation.isExpired),
  };
}

export function toGroupMembership({
  participant_uid: id,
  joined_at: joinedAt,
  latest_sync,
  label_uids: labelIDs,
  email,
  name,
}: ApiGroupMembership): GroupMembership {
  return {
    id,
    email,
    name,
    labelIDs,
    joinedAt: parseTimestamp(joinedAt),
    latestSync: latest_sync ? parseTimestamp(latest_sync) : null,
  };
}

export function toOrganizationMemberSearch({
  participant_uid: memberID,
  name,
  email,
  group_uid: groupID,
  joined_at: joinedAt,
}: ApiOrgMemberSearchResult): OrgMemberSearchResult {
  return {
    memberID,
    name,
    email,
    groupID,
    joinedAt: parseTimestamp(joinedAt),
  };
}

export function toBlackOutPeriod({
  id,
  valid_from,
  valid_to,
  features,
  comment,
}: ApiBlackoutPeriod): BlackoutPeriod {
  return {
    id,
    validFrom: parseTimestamp(valid_from),
    validTo: parseTimestamp(valid_to),
    features,
    comment: comment || undefined,
  };
}

export function toGroupMembersDailyMetrics(
  locale: LocaleData,
  data: GetGroupMembersDailyMetricsResponse,
  metrics: MetricKey[],
  startDate: Date,
  endDate: Date,
  existingData?: GroupMembersDailyMetrics['dailyData'],
): GroupMembersDailyMetrics['dailyData'] {
  const eachDayInPeriod = eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).map((d) => toDateString(d));

  // We need to be careful in the following methods. We're mutating objects
  // instead of creating new ones due to how many data points we need to update,
  // which would make this a very heavy operation if it was all done immutably.
  // We want to be sure that we don't accidentally mutate old objects, only fresh
  // ones.
  return Object.entries(data).reduce<GroupMembersDailyMetrics['dailyData']>(
    (result, [memberID, newMemberData]) => {
      const updatedDailyData = {
        ...existingData?.[memberID],
      };

      // Make sure data object exists for each object
      metrics.forEach((metricKey) => {
        updatedDailyData[metricKey] = { ...updatedDailyData[metricKey] };
      });

      // Create a map of the new data
      const dayDataMap = newMemberData.reduce<{
        [date: string]:
          | {
              [metricID: string]: number | null;
            }
          | undefined;
      }>((acc, { date, ...metricsData }) => {
        acc[date] = mapValues(metricsData, (d) => (d == null ? null : d));
        return acc;
      }, {});

      // Map over each day in period and insert the value or null from the map
      eachDayInPeriod.forEach((date) => {
        const metricsData = dayDataMap[date];
        // If metricsData is missing it means the user was not a member in the group at that point.
        metrics.forEach((metricKey) => {
          const value = metricsData?.[metricKey];
          updatedDailyData[metricKey]![date] =
            value == null ? null : toMeasurementValue(locale, metricKey, value);
        });
      });

      // eslint-disable-next-line no-param-reassign
      result[memberID] = updatedDailyData;
      return result;
    },
    {},
  );
}

function toMetricAggregates(aggregates: ApiMetricAggregates): MetricAggregates {
  return {
    baselineDayCount: aggregates.baseline_day_count,
    baseline: aggregates.baseline,
    percentiles: aggregates.percentiles,
    metricRatingLimits: aggregates.metric_rating_limits,
  };
}

export function toGroupAggregates(data: ApiGroupAggregates): GroupAggregates {
  // We're dropping the data dates here. Can add them if we need them at some point.
  return mapValues(data, (memberData) =>
    mapValues(memberData.aggregates, toMetricAggregates),
  );
}

export function toGroupLabel({
  uid: id,
  created_at,
  ...rest
}: ApiGroupLabel): GroupLabel {
  return {
    id,
    createdAt: parseTimestamp(created_at),
    ...rest,
  };
}

export function toGroupMemberNotes(
  response: ApiGetGroupMemberNotesResponse,
): GroupMemberNotes {
  switch (response.status) {
    case UserNoteStatus.PermissionDenied:
      return response;
    default:
      return toUserNotes(response);
  }
}

export function toGroupNotes(group: ApiGetGroupNotesResponse): GroupNotes {
  return Object.keys(group).reduce<{
    [participantId: string]: GroupMemberNotes;
  }>(
    (result, key) => ({ ...result, [key]: toGroupMemberNotes(group[key]) }),
    {},
  );
}

export function toOrganizationDataExportSettings(
  data: ApiGetOrganizationDataExportSettingsResponse,
): OrganizationDataExportSettings {
  return {
    dataTypes: data.data_types,
    groups: data.groups.map((group) => ({
      id: group.uid,
      name: group.name,
      createdAt: parseTimestamp(group.created_at),
      isPrivate: group.is_private,
      isAnonymous: group.is_anonymous,
      accessLevel: group.access_level,
    })),
    labels: data.labels.map((label) => ({
      id: label.uid,
      name: label.name,
      createdAt: parseTimestamp(label.created_at),
      groupName: label.group_name,
      groupId: label.group_uid,
      color: label.color,
    })),
    members: data.participants
      ? data.participants.map((participant) => ({
          id: participant.uid,
          name: participant.name,
        }))
      : undefined,
  };
}

export function toExportItem(
  data: ApiGetDataExportsResponse,
): DataExportItem[] {
  return data.items.map((item) => ({
    dataType: item.data_types.join(', '),
    dateRange: `${item.start_time} - ${item.end_time}`,
    name: item.task_name,
    status: item.status,
    time: parseTimestamp(item.start_time),
    type: item.export_config.format,
    createdAt: parseTimestamp(item.created_at),
    downloadLink:
      item.files && item.files.length > 0 && item.files[0]?.url
        ? item.files[0].url
        : undefined,
    expiresAt: parseTimestamp(item.expires_at),
  }));
}
